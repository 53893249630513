export const STATUS = [
  {
    dictTypeName: "问卷状态",
    dictType: "survey_status",
    dictCode: 1,
    dictName: "未发送",
    dictNameEn: "Unsent"
  },
  {
    dictTypeName: "问卷状态",
    dictType: "survey_status",
    dictCode: 2,
    dictName: "已发送",
    dictNameEn: "Sent"
  },
  {
    dictTypeName: "问卷状态",
    dictType: "survey_status",
    dictCode: 3,
    dictName: "已填写",
    dictNameEn: "Completed"
  },
  {
    dictTypeName: "问卷状态",
    dictType: "survey_status",
    dictCode: 4,
    dictName: "已取消",
    dictNameEn: "Canceled"
  }
];

export const NOTIFY = [
  {
    dictTypeName: "通知方式",
    dictType: "survey_notify",
    dictCode: 1,
    dictName: "短信",
    dictNameEn: "SMS"
  },
  {
    dictTypeName: "通知方式",
    dictType: "survey_notify",
    dictCode: 2,
    dictName: "邮件",
    dictNameEn: "Email"
  }
];

export const APPOINTMENT = [
  {
    dictTypeName: "是否预约发送",
    dictType: "survey_appointment",
    dictCode: 1,
    dictName: "是",
    dictNameEn: "Yes"
  },
  {
    dictTypeName: "是否预约发送",
    dictType: "survey_appointment",
    dictCode: 2,
    dictName: "否",
    dictNameEn: "No"
  }
];

export const METHOD = [
  {
    dictTypeName: "发布方式",
    dictType: "survey_method",
    dictCode: 1,
    dictName: "录入信息",
    dictNameEn: "Form Data"
  },
  {
    dictTypeName: "发布方式",
    dictType: "survey_method",
    dictCode: 2,
    dictName: "选择员工",
    dictNameEn: "Employees"
  },
  {
    dictTypeName: "发布方式",
    dictType: "survey_method",
    dictCode: 3,
    dictName: "选择候选人",
    dictNameEn: "Candidates"
  },
  {
    dictTypeName: "发布方式",
    dictType: "survey_method",
    dictCode: 4,
    dictName: "导入信息",
    dictNameEn: "Data Import"
  }
];
