<template>
  <div class="survey-candidate">
    <ECTable
      :columns="columns"
      :data-source="list"
      :loading="loading"
      :pagination="{ total, current: pageNum, pageSize }"
      @change="onTableChange"
      :row-selection="rowSelection"
    >
      <template slot="footerCustom">
        <div></div>
      </template>
    </ECTable>
  </div>
</template>

<script>
import ECTable from "@/components/ECTable";
import { pageStaff } from "@/pages/HR/api";
import { showMsg } from "@/utils";
import { getCandidateList } from "@/pages/home/api";

export default {
  name: "SurveyCandidate",
  components: { ECTable },
  props: {},
  data() {
    return {
      list: [],
      columns: [
        {
          title: this.$t("survey.index.form.candidate.name"),
          dataIndex: "candidateName",
          ellipsis: true,
          width: 90,
          align: "left"
        },
        {
          title: this.$t("survey.index.form.candidate.idcard"),
          dataIndex: "idNumber",
          ellipsis: true,
          width: 180,
          align: "left"
        },
        {
          title: this.$t("survey.index.form.candidate.email"),
          dataIndex: "candidateEmail",
          ellipsis: true,
          width: 200,
          align: "left"
        },
        {
          title: this.$t("survey.index.form.candidate.phone"),
          dataIndex: "phone",
          ellipsis: true,
          width: 140,
          align: "left"
        }
      ],
      selectedRowKeys: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      loading: false
    };
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: selectedRowKeys => {
          this.selectedRowKeys = selectedRowKeys;
        }
        // getCheckboxProps: (record) => ({
        //   props: {
        //     value: record.userId,
        //   },
        // }),
      };
    }
  },
  methods: {
    async getList() {
      this.loading = true;
      const res = await getCandidateList({
        pageIndex: this.pageNum,
        pageSize: this.pageSize
      });
      const list = res.data.list || [];
      list.forEach((it, idx) => {
        it.key = (this.pageNum - 1) * this.pageSize + idx;
      });
      this.list = list;
      this.total = res.data.total;
      this.loading = false;
    },
    onTableChange(page) {
      this.pageNum = page.current;
      this.pageSize = page.pageSize;
      this.getList();
    },
    onValid() {
      if (!this.selectedRowKeys.length) {
        showMsg({
          flag: 1,
          msg: this.$t("survey.index.form.candidateRequired")
        });
        return false;
      }
      return this.selectedRowKeys;
    }
  },
  mounted() {
    this.getList();
  }
};
</script>

<style scoped lang="less"></style>
