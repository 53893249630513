<template>
  <div class="survey-form-wrapper">
    <a-form-model
      ref="formRef"
      class="survey-form"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-row>
        <!--姓名-->
        <a-col :span="24">
          <a-form-model-item :label="$t('survey.index.table.name')" prop="name">
            <a-input
              v-model="form.name"
              :placeholder="messageFormatter($t('input'), $t('survey.index.table.name'))"
            />
          </a-form-model-item>
        </a-col>
        <!--通知方式-->
        <a-col :span="24">
          <a-form-model-item :label="$t('survey.index.table.notify')" prop="notify">
            <a-radio-group
              v-model="form.notify"
              :options="notifyList"
            />
          </a-form-model-item>
        </a-col>
        <!--邮箱地址-->
        <a-col :span="24">
          <a-form-model-item :label="$t('survey.index.table.email')" prop="email">
            <a-input
              v-model="form.email"
              :placeholder="messageFormatter($t('input'), $t('survey.index.table.email'))"
            />
          </a-form-model-item>
        </a-col>
        <!--电话号码-->
        <a-col :span="24">
          <a-form-model-item :label="$t('survey.index.table.phone')" prop="phone">
            <a-input
              v-model="form.phone"
              :placeholder="messageFormatter($t('input'), $t('survey.index.table.phone'))"
            />
          </a-form-model-item>
        </a-col>
        <!--使用调研点-->
        <a-col :span="24">
          <a-form-model-item label=" " :colon="false">
            {{ $t('survey.index.form.cost', { val: 0, name: surveyCoinName }) }}
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { langMixin } from "@/mixins";
import { NOTIFY } from "@/pages/workspace/survey/config";
import { dict2LabelValue } from "@/utils/tools";
import { mapGetters } from "vuex";
import { FETCH_CODE, REG_EMAIL } from "@/config";
import { showMsg } from "@/utils";

const formEmpty = {
  // 姓名
  name: "",
  // 通知方式
  notify: undefined,
  // 邮箱地址
  email: "",
  // 电话号码
  phone: ""
};

export default {
  name: "SurveyForm",
  props: {},
  mixins: [langMixin],
  data() {
    return {
      form: cloneDeep(formEmpty),
      rules: {
        name: [
          { required: true, message: this.$t("survey.index.form.nameRequired") },
          { max: 50, message: this.$t("survey.index.form.nameMax", { len: 50 }) },
        ],
        notify: [
          { required: true, message: this.$t("survey.index.form.notifyRequired") },
        ],
        email: [
          { required: true, message: this.$t("survey.index.form.emailRequired") },
          { max: 50, message: this.$t("survey.index.form.emailMax", { len: 100 }) },
          { pattern: REG_EMAIL, message: this.$t('survey.index.form.emailReg') },
        ],
        phone: [
          { required: true, message: this.$t("survey.index.form.phoneRequired") },
          { max: 50, message: this.$t("survey.index.form.phoneMax", { len: 50 }) },
        ],
      },
      labelCol: { span: 7 },
      wrapperCol: { span: 15 },
      // 通知方式列表
      notifyList: this.listLabelFormatter(dict2LabelValue(cloneDeep(NOTIFY))),
    };
  },
  computed: {
    ...mapGetters("user", ["surveyCoinName"]),
  },
  methods: {
    onValid() {
      return new Promise((resolve) => {
        this.$refs.formRef.validate((valid, errors) => {
          if (!valid) {
            showMsg({
              flag: FETCH_CODE.WARNING.KEY,
              msg: Object.values(errors)[0]?.[0]?.message,
            });
            resolve(false);
            return;
          }
          resolve(this.form);
        });
      });
    },
    onReset() {
      this.form = cloneDeep(formEmpty);
      this.$refs.formRef.resetFields();
    },
  }
}
</script>

<style scoped lang="less"></style>
