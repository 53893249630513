import api from "@/utils/fetch";
import { FETCH_CODE } from "@/config";

/**
 * 查询列表
 * @param params
 * @param {number} params.pageNum 当前页页码
 * @param {number} params.pageSize 每页的数量
 * @param {string=} params.companyId 企业唯一ID
 * @param {string=} params.phone 手机号
 * @param {number=} params.type 用户类型 1:后台 2:hr 3:员工 UserType
 * @param {string=} params.userName 用户名称（模糊查询）
 * @return {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export async function pageStaff(params) {
  const res = await api.get("/company/staff/list", params);
  if (res.flag === FETCH_CODE.SUCCESS.KEY) {
    if (!res.data) {
      res.data = {
        records: [],
        current: params.pageNum,
        size: params.pageSize,
        total: 0,
      };
    }
  }
  return res;
}

/**
 * 添加员工
 * @param params
 * @param {number=} params.id id
 * @param {string=} params.name 姓名
 * @param {number} params.userType  用户类型 1:后台 2:hr 3:员工 UserType
 * @param {number=} params.mobilePhoneCode 手机号区号
 * @param {string=} params.mobilePhoneNumber 手机号
 * @param {string=} params.telephoneNumber 办公座机
 * @param {string=} params.emailAddress 邮件地址
 * @param {boolean=} params.gender 性别
 * @param {string=} params.birthday 生日
 * @param {string=} params.induction 入职日期
 * @param {boolean=} params.master 是否为主账号 1 是 0 否
 * @param {string[]=} params.roleIds 账户角色
 * @param {string=} params.headImg 头像
 * @param {boolean=} params.sendMsg 发送激活通知
 * @param {Object} params.userCompany 所属公司信息
 * @param {string=} params.userCompany.companyId 	企业唯一ID
 * @param {number=} params.userCompany.activeStatus 在职状态 1 在职 2 离职
 * @param {boolean=} params.userCompany.isCurrent 是否为当前企业
 * @param {boolean=} params.userCompany.joinGrantPoints 是否参与发放积分
 * @param {boolean=} params.userCompany.master 是否为主账号 1 是 0 否
 * @param {string=} params.userCompany.userId 用户唯一ID
 * @param {string=} params.deptId 部门id
 * @return {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function addStaff(params) {
  return api.post("/company/staff/add", params);
}

/**
 * 更新
 * @param {string=} params.birthday 生日
 * @param {string=} params.deptId 部门id
 * @param {string=} params.emailAddress 邮件地址
 * @param {boolean=} params.gender 性别
 * @param {string=} params.headImg 头像
 * @param {number=} params.id id
 * @param {string=} params.induction 入职日期
 * @param {boolean=} params.master 是否为主账号 1 是 0 否
 * @param {number=} params.mobilePhoneCode 手机号区号
 * @param {string=} params.mobilePhoneNumber 手机号
 * @param {string=} params.name 姓名
 * @param {string[]=} params.roleIds 账户角色
 * @param {boolean=} params.sendMsg 发送激活通知
 * @param {string=} params.telephoneNumber 办公座机
 * @param {number} params.userType  用户类型 1:后台 2:hr 3:员工 UserType
 * @param {Object} params.userCompany 所属公司信息
 * @param {number=} params.userCompany.activeStatus 在职状态 1 在职 2 离职
 * @param {string=} params.userCompany.companyId  企业唯一ID
 * @param {boolean=} params.userCompany.isCurrent 是否为当前企业
 * @param {boolean=} params.userCompany.joinGrantPoints 是否参与发放积分
 * @param {boolean=} params.userCompany.master 是否为主账号 1 是 0 否
 * @param {string=} params.userCompany.userId 用户唯一ID
 * @return {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function updateStaff(params) {
  return api.post("/company/staff/update", params);
}

/**
 * 员工信息
 * @param params
 * @param {number} params.userId
 * @return {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function infoStaff(params) {
  return api.get("/company/staff/info", params);
}

/**
 * 导出模板
 * @param params
 * @return {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function templateStaff(params) {
  return api.download("/company/staff/template", params, {
    method: "GET",
  });
}

/**
 * 禁用启用
 * @param params
 * @param {number} params.id
 * @param {boolean} params.isDisabled
 * @return {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function statusStaff(params) {
  return api.post("/company/staff/updateStatus", params);
}

// 员工离职
export function leaveStaff(params) {
  return api.post("/company/staff/leave", params);
}

