<template>
  <div class="survey-import">
    <a-form-model
      :model="form"
      :rules="formRules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      ref="formRef"
    >
      <div class="batch-header">
        <div class="batch-header-text">
          {{ $t("welfarePlat.manualRelease.batchTips") }}
        </div>
        <a-button
          type="primary"
          ghost
          shape="round"
          size="small"
          @click="onDownload"
        >
          {{ $t("welfarePlat.manualRelease.batchDownload") }}
        </a-button>
      </div>
      <a-form-model-item
        :label="$t('welfarePlat.manualRelease.batchUpload')"
        prop="fileList"
      >
        <EcUpload
          :fileName="downloadFileName"
          formName="file"
          action="/api/company/benefits/grant/template/import"
          :fileList.sync="form.fileList"
          :params="{}"
          @change="onFileChange"
          :tips="$t('survey.index.form.import.upload')"
        />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import EcUpload from "@/components/EcUpload.vue";
import { langMixin } from "@/mixins";
import moment from "moment";
import { FETCH_CODE, REG_EMAIL, REG_NAN } from "@/config";
import { showMsg } from "@/utils";
import { read, utils } from "xlsx";
import api from "@/utils/fetch";
import { pointsShowFormatter } from "@/utils/tools";

const formEmpty = {
  fileList: []
};

export default {
  name: "SurveyImport",
  components: { EcUpload },
  mixins: [langMixin],
  props: {},
  data() {
    return {
      form: { ...formEmpty },
      formRules: {},
      fileAccept:
        "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    };
  },
  computed: {
    labelCol() {
      return this.calcFormItemLayout([5, 7], [18, 16]).labelCol;
    },
    wrapperCol() {
      return this.calcFormItemLayout([5, 7], [18, 16]).wrapperCol;
    },
    downloadFileName() {
      let name = this.$t("survey.index.form.import.fileName");
      name += moment().format(".YYYYMMDDHHmmss");
      return name + ".xlsx";
    }
  },
  methods: {
    onDownload() {
      const a = document.createElement("a");
      a.href = "/template/StaffTemplate.xlsx";
      a.download = this.downloadFileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    onFileChange() {}
  }
};
</script>

<style scoped lang="less">
.batch-header {
  font-size: @font-size-large;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  &-text {
    margin-right: 14px;
  }
}
</style>
