<template>
  <a-upload-dragger
    :name="formName"
    :accept="accept"
    :data="onData"
    :action="action"
    :headers="{ Authorization: cookies.EC_TOKEN }"
    :withCredentials="true"
    :fileList="fileList"
    @change="onChange"
    :beforeUpload="onBeforeUpload"
    :disabled="disabled"
    :showUploadList="showUploadList"
    :customRequest="customRequest"
  >
    <slot name="icon">
      <p class="ant-upload-drag-icon">
        <a-icon type="inbox" />
      </p>
    </slot>
    <p class="ant-upload-text">
      {{ tips || $t("button.uploadDragger") }}
    </p>
    <slot name="hint"></slot>
  </a-upload-dragger>
</template>

<script>
import { cookies } from "@/config";
import { showMsg } from "@/utils";
import { fileSizeFormatter } from "@/utils/tools";

export default {
  name: "EcUpload",
  computed: {
    cookies() {
      return cookies;
    }
  },
  props: {
    formName: {
      type: String,
      default: "file",
    },
    fileName: String,
    params: {
      type: Object,
    },
    accept: String,
    limit: Number,
    action: {
      type: String,
      required: true,
    },
    decorator: Array,
    fileList: Array,
    disabled: Boolean,
    tips: String,
    showUploadList: {
      type: Boolean,
      default: true,
    },
    beforeUpload: {
      type: Function,
      default: (file) => Promise.resolve(file),
    },
    toast: {
      type: Boolean,
      default: true,
    },
    // 支持默认上传方式
    customRequest: Function,
  },
  data() {
    return {};
  },
  methods: {
    onChange(e) {
      const _fileList = [];
      const errorMsgList = [];
      e.fileList.forEach((it) => {
        if (!["done", "error"].includes(it.status)) {
          _fileList.push(it);
          return;
        }
        // 上传不成功的提示错误
        if (it.response) {
          if (it.response.code === 200) {
            _fileList.push(it);
            return;
          }
          errorMsgList.push(it.response.msg);
        }
      });
      if (errorMsgList.length) {
        showMsg({ flag: 2, msg: errorMsgList.join("，") });
      }
      this.$emit("update:fileList", _fileList);
      this.$emit("change", _fileList);
    },
    async onBeforeUpload(file) {
      const _fileList = this.fileList.slice();
      const errorFileFormatter = (status, msg) => {
        return {
          error: {
            method: "post",
            status: status,
            url: this.action,
          },
          lastModified: file.lastModified,
          lastModifiedDate: file.lastModifiedDate,
          name: file.name,
          originFileObj: file,
          percent: 100,
          response: {
            code: status,
            data: null,
            msg,
            success: false,
            tid: null,
          },
          size: file.size,
          status: "error",
          type: file.type,
          uid: file.uid,
        };
      };
      return new Promise((resolve, reject) => {
        // 判断文件格式
        let isFormatValid = true;
        if (this.accept) {
          isFormatValid = this.accept.includes(file.type);
          if (!isFormatValid) {
            this.toast && showMsg({ flag: 1, msg: this.$t("file.format") });
            this.onChange({ fileList: [..._fileList, errorFileFormatter(601, this.$t("file.format"))]});
          }
        }

        // 判断文件最大限制
        let isSizeMaxValid = true;
        if (this.limit) {
          isSizeMaxValid = file.size < this.limit;
          if (!isSizeMaxValid) {
            this.toast && showMsg({ flag: 1, msg: this.$t("file.sizeMax", { value: fileSizeFormatter(this.limit) }) });
            this.onChange({
              fileList: [
                ..._fileList,
                errorFileFormatter(602, this.$t("file.sizeMax", { value: fileSizeFormatter(this.limit) }))
              ]
            });
          }
        }

        const valid = isFormatValid && isSizeMaxValid;
        if (valid) {
          resolve(file);
          return;
        }
        reject(file);
      });
    },
    onData(file) {
      return { ...this.params, name: this.fileName || file.name };
    },
  },
}
</script>

<style scoped lang="less">

</style>
