<template>
  <div class="survey-index">
    <ListFilter :form-list="formList" @reset="onReset" @filter="onSearch" />
    <ECTable
      showTitle
      :title="$t('survey.index.title')"
      :columns="columns"
      :data-source="list"
      bordered
      rowKey="id"
      key="id"
      :loading="loading"
      :pagination="{ total, current, pageSize }"
      @change="onTableChange"
    >
      <div slot="tableHeaderRight" class="table-buttons">
        <a-button type="link" @click="onDemo">
          {{ $t("survey.order.link") }}
        </a-button>
        <a-dropdown>
          <a-button type="primary">
            {{ $t("survey.index.add") }}
          </a-button>
          <a-menu slot="overlay">
            <a-menu-item v-for="m in methodList" :key="m.value">
              <a href="javascript: void(0);" @click="onAdd(m.value, m)">
                {{ m.label }}
              </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
      <!-- 操作列 -->
      <template slot="action" slot-scope="text, record">
        <div class="table-operations">
          <a-button type="link" class="table-btn warning" @click="onCancel(record)">
            <a-icon type="undo" />
            {{ $t("button.cancel") }}
          </a-button>
        </div>
      </template>
    </ECTable>

    <a-modal
      :visible="formVisible"
      @cancel="onFormCancel"
      @ok="onFormConfirm"
      class="survey-form-modal"
      :maskClosable="false"
      destroyOnClose
      :title="$t('survey.index.add')"
      :width="modalWidth"
    >
      <SurveyForm ref="formRef" v-if="method === methodList[0].value" />
      <SurveyEmployee ref="employeeRef" v-if="method === methodList[1].value" />
      <SurveyCandidate ref="candidateRef" v-if="method === methodList[2].value" />
      <SurveyImport ref="importRef" v-if="method === methodList[3].value" />
    </a-modal>
    <a-modal
      :visible="resultVisible"
      @cancel="onResultCancel"
      @ok="onResultConfirm"
      class="survey-result-modal"
      :maskClosable="false"
      destroyOnClose
      :title="$t('survey.index.form.confirm')"
      width="500px"
    >
      <SurveyAddResult ref="resultRef" />
    </a-modal>
  </div>
</template>

<script>
import ECTable from "@/components/ECTable";
import { cloneDeep } from "lodash";
import ListFilter from "@/pages/home/components/ListFilter.vue";
import {
  APPOINTMENT,
  METHOD,
  NOTIFY,
  STATUS
} from "@/pages/workspace/survey/config";
import { confirmModal, dict2LabelValue } from "@/utils/tools";
import { langMixin } from "@/mixins";
import SurveyForm from "./components/Form.vue";
import SurveyEmployee from "./components/Employee.vue";
import SurveyCandidate from "./components/Candidate.vue";
import SurveyImport from "./components/Import.vue";
import SurveyAddResult from "./components/Result.vue";
import { mapGetters } from "vuex";

export default {
  name: "SurveyIndex",
  components: {
    ListFilter,
    ECTable,
    SurveyForm,
    SurveyEmployee,
    SurveyCandidate,
    SurveyImport,
    SurveyAddResult,
  },
  mixins: [langMixin],
  data() {
    return {
      form: {
        companyId: "",
        from: "",
        to: ""
      },
      list: [],
      loading: false,
      total: 0,
      current: 1,
      pageSize: 10,
      formVisible: false,
      formLoading: false,
      resultVisible: false,
      // 通知方式列表
      notifyList: this.listLabelFormatter(dict2LabelValue(cloneDeep(NOTIFY))),
      // 问卷列表
      surveyList: [],
      // 是否预约发送列表
      appointmentList: this.listLabelFormatter(dict2LabelValue(cloneDeep(APPOINTMENT))),
      // 问卷状态列表
      statusList: this.listLabelFormatter(dict2LabelValue(cloneDeep(STATUS))),
      // 发布问卷方式
      methodList: this.listLabelFormatter(dict2LabelValue(cloneDeep(METHOD))),
      method: METHOD[0].dictCode,
    };
  },
  computed: {
    ...mapGetters("user", ["surveyCoinName"]),
    formList() {
      return [
        // 姓名
        {
          label: this.$t("survey.index.table.name"),
          key: "name",
          type: "input"
        },
        // 通知方式
        {
          label: this.$t("survey.index.table.notify"),
          type: "select",
          key: "notify",
          options: this.notifyList,
        },
        // 问卷名称
        {
          label: this.$t("survey.index.table.title"),
          type: "select",
          key: "title",
          options: this.surveyList,
        },
        // 电话号码
        {
          label: this.$t("survey.index.table.phone"),
          key: "phone",
          type: "input"
        },
        // 邮箱地址
        {
          label: this.$t("survey.index.table.email"),
          key: "email",
          type: "input"
        },
        // 创建时间
        {
          label: this.$t("survey.index.table.create"),
          key: "create",
          type: "timeRange"
        },
        // 是否预约发送
        {
          label: this.$t("survey.index.table.appointment"),
          type: "select",
          key: "appointment",
          options: this.appointmentList,
        },
        // 问卷状态
        {
          label: this.$t("survey.index.table.status"),
          type: "select",
          key: "status",
          options: this.statusList,
        },
      ];
    },
    currentMethod() {
      const curr = METHOD.find((it) => it.dictCode === this.method);
      return curr || {};
    },
    columns() {
      return  [
        {
          title: this.$t("survey.index.table.name"),
          dataIndex: "name",
          width: 120,
          align: "left",
        },
        {
          title: this.$t("survey.index.table.title"),
          dataIndex: "code",
          width: 120,
          align: "left",
        },
        {
          title: this.surveyCoinName,
          dataIndex: "value",
          width: 100,
          align: "right",
        },
        {
          title: this.$t("survey.index.table.notify"),
          dataIndex: "state",
          width: 90,
          align: "center",
        },
        {
          title: this.$t("survey.index.table.phone"),
          dataIndex: "isEncryption",
          width: 160,
          align: "left",
        },
        {
          title: this.$t("survey.index.table.email"),
          dataIndex: "createBy",
          width: 160,
          align: "left",
        },
        {
          title: this.$t("survey.index.table.create"),
          dataIndex: "createTime",
          width: 160,
          align: "center",
        },
        {
          title: this.$t("survey.index.table.send"),
          dataIndex: "updateTime",
          width: 160,
          align: "center",
        },
        {
          title: this.$t("survey.index.table.submit"),
          dataIndex: "updateTime",
          width: 160,
          align: "center",
        },
        {
          title: this.$t("survey.index.table.appointment"),
          dataIndex: "remark",
          align: "center",
          width: 140,
        },
        {
          title: this.$t("survey.index.table.status"),
          dataIndex: "remark",
          align: "center",
          width: 100,
        },
        {
          title: this.$t("action"),
          dataIndex: "action",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 100,
          fixed: "right",
        },
      ];
    },
    modalWidth() {
      if (this.method === METHOD[0].dictCode) {
        return "500px";
      }
      if (this.method === METHOD[1].dictCode) {
        return "660px";
      }
      if (this.method === METHOD[2].dictCode) {
        return "720px";
      }
      if (this.method === METHOD[3].dictCode) {
        return "500px";
      }
      return void 0;
    }
  },
  methods: {
    // 查询
    onSearch(form) {
      this.form = form;
      this.current = 1;
      this.getList();
    },
    // 分页
    onTableChange(page) {
      this.current = page.current;
      this.pageSize = page.pageSize;
      this.getList();
    },
    // 新增
    onAdd(method) {
      this.method = method;
      this.formVisible = true;
    },
    // 取消
    async onCancel(record) {
      if (this.loading) return;
      this.loading = true;
      const confirmed = await confirmModal(
        this.$t("survey.index.confirm"),
        (h) => (
          <div>
            {this.$t("survey.index.confirmText1")}
            <span class="ec-text-danger">{record.name}</span>
            {this.$t("survey.index.confirmText2")}
          </div>
        )
      );
      if (!confirmed) {
        this.loading = false;
        return;
      }
      this.loading = false;
    },
    // 弹窗取消事件
    onFormCancel() {
      this.formVisible = false;
    },
    // 弹窗确认事件
    async onFormConfirm() {
      if (this.formLoading) return;
      this.formLoading = true;
      const res = await this.onFormConfirmEvent();
      if (!res) {
        this.formLoading = false;
        return;
      }
      this.formLoading = false;
      this.onFormCancel();
      this.getList();
    },
    async onFormConfirmEvent() {
      // 录入信息
      if (this.method === METHOD[0].dictCode) {
        console.log("录入信息");
        const res = await this.$refs.formRef.onValid();
        if (!res) {
          return false;
        }
        console.log(res);
        return true;
      }
      // 选择员工
      if (this.method === METHOD[1].dictCode) {
        console.log("选择员工");
        const res = await this.$refs.employeeRef.onValid();
        if (!res) {
          return false;
        }
        console.log(res);
        this.resultVisible = true;
        return true;
      }
      // 选择候选人
      if (this.method === METHOD[2].dictCode) {
        console.log("选择候选人");
        const res = await this.$refs.candidateRef.onValid();
        if (!res) {
          return false;
        }
        console.log(res);
        this.resultVisible = true;
        return true;
      }
      // 导入信息
      if (this.method === METHOD[3].dictCode) {
        console.log("导入信息");
        return true;
      }
    },
    // 获取列表
    async getList() {
      this.loading = true;
      const params = {
        pageNum: this.current,
        pageSize: this.pageSize,
        ...this.form,
      };
      console.log(params);
      const res = {
        data: {
          records: [],
          size: 10,
          current: 1,
          total: 1,
        },
      };
      this.list = res.data.records || [];
      this.current = res.data.current;
      this.total = res.data.total;
      this.pageSize = res.data.size;
      this.loading = false;
    },
    // 查看样本报告
    onDemo() {},
    // 重置
    onReset() {
      this.form = {};
    },
    // 结果取消
    onResultCancel() {
      this.resultVisible = false;
    },
    // 结果确认
    async onResultConfirm() {
      const res = await this.$refs.resultRef.onValid();
      if (!res) {
        return;
      }
      console.log(res);
      this.onResultCancel();
    },
  },
  mounted() {
    this.getList();
  },
}
</script>

<style lang="less" scoped>
.search-head {
  padding: @ec-gutter20 0;

  .search-input {
    width: 300px;
    margin-right: @ec-gutter;
  }
}
.ec-text-danger {
  color: @error-color;
}
</style>
