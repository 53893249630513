<template>
  <div class="survey-add-result">
    <div>本次发布13份问卷，使用0调研点</div>
    <a-form-model
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      ref="formRef"
    >
      <a-row>
        <!--通知方式-->
        <a-col :span="24">
          <a-form-model-item
            :label="$t('survey.index.table.notify')"
            prop="notify"
          >
            <a-radio-group v-model="form.notify" :options="notifyList" />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { FETCH_CODE } from "@/config";
import { langMixin } from "@/mixins";
import { showMsg } from "@/utils";
import { dict2LabelValue } from "@/utils/tools";
import { NOTIFY } from "@/pages/workspace/survey/config";

const formEmpty = {
  // 通知方式
  notify: undefined
};

export default {
  name: "SurveyAddResult",
  mixins: [langMixin],
  data() {
    return {
      form: cloneDeep(formEmpty),
      rules: {
        notify: [
          {
            required: true,
            message: this.$t("survey.index.form.notifyRequired")
          }
        ]
      },
      labelCol: { span: 7 },
      wrapperCol: { span: 15 },
      // 通知方式列表
      notifyList: this.listLabelFormatter(dict2LabelValue(cloneDeep(NOTIFY)))
    };
  },
  methods: {
    onValid() {
      return new Promise(resolve => {
        this.$refs.formRef.validate((valid, errors) => {
          if (!valid) {
            showMsg({
              flag: FETCH_CODE.WARNING.KEY,
              msg: Object.values(errors)[0]?.[0]?.message
            });
            resolve(false);
            return;
          }
          resolve(this.form);
        });
      });
    },
    onReset() {
      this.form = cloneDeep(formEmpty);
      this.$refs.formRef.resetFields();
    }
  }
};
</script>

<style lang="less" scoped></style>
